// Global
@import './global/variables';
@import './global/mixins';
@import './global/reset';

.canvas-gallery {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    -webkit-tap-highlight-color:transparent;
    -webkit-touch-callout:none;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
}

body {
    overflow: hidden;
}
