* {
    box-sizing: border-box;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

ol, ul {
    list-style: none;
    margin: 0;
    padding: 0;
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

a {
    display: inline-block;
    color: currentColor;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

a, button, input, textarea {
    &:focus {
        outline: none;
    }
}

button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:focus,
    &:active {
        outline: 0;
        box-shadow: 0 0 0;
    }
}


a,
button {
    transition: all 0.3s ease;
}


picture {
    display: block;
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
}

textarea,
input[type='text'],
input[type='tel'],
input[type='email'],
input[type='button'],
input[type='search'],
input[type='password'] {
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0;
    box-shadow: 0 0 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type='search']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}

input[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
    display: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='submit'] {
    cursor: pointer;
}

strong, b {
    font-weight: 600;
}

video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
